<template>

  <div class='bodyContent' style='display: block;'>
    <div class='Home'>
      <div id='HomeContributeArea' class='ContributeArea'>
        <div class='child-main'>
         <div>
            <el-input v-model="nums" placeholder="请输入数量">
              <template slot="prepend">数量</template>
            </el-input>
          </div>
          <div>
            <el-input v-model="privateKey" placeholder="请输入私钥">
              <template slot="prepend">私钥</template>
            </el-input>
          </div>
          <div>
            <el-input
              v-model="walletlist"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8}"
              placeholder="请输入转入钱包地址，用逗号隔开">
            </el-input>
          </div>
          <div>
            <el-button @click='manyTransfer()'>确认转账</el-button>
          </div>


        </div>
      </div>
    </div>
  </div>




</template>

<script>
import Web3 from 'web3'

export default {
  name: 'SendTransaction',
  components: {  },

  data() {
    return {
	  nums:0.001,
      privateKey:'',
      walletlist:'',
      bscurl:'https://opbnb-mainnet-rpc.bnbchain.org/',
    }
  },
  head(){
    return {
      title: '钱包批量转账',
    }
  },
  // eslint-disable-next-line require-await
  async mounted() {
    this.checkWeb3()
    const web3provider = this.bscurl
    window.web3 = new Web3(web3provider)
    this.web3 = new Web3(web3provider)
  },
  methods: {
    checkWeb3() {
      const web3 = window.web3
      if (typeof web3 === 'undefined') {
        this.web3 = null
        // eslint-disable-next-line no-console
        console.log(this.MetamaskMsg.METAMASK_NOT_INSTALL, 'NO_INSTALL_METAMASK')
      }else {
        // eslint-disable-next-line no-console
        console.log('MetaMask is installed!');
      }
    },
    async transfermoney(amount,toaddr,randomnum) {
      const account = this.web3.eth.accounts.privateKeyToAccount(this.privateKey)
      // eslint-disable-next-line no-console
      console.log(account)
      // eslint-disable-next-line no-undef
      const value1 = await this.web3.utils.toWei(amount,'ether')
      // eslint-disable-next-line no-undef
      const gasPrice = await this.web3.eth.getGasPrice()
      // const toaddr = '0x714C983a130f7798e7482488FbE76E4B5Af006D6'
      const tx = {
        nonce: await this.web3.eth.getTransactionCount(account.address),
        from: account.address,
        to: toaddr,
        value:value1,
        gas: 21000,
        gasPrice
      }
      const signedTx = await account.signTransaction(tx)
      return await this.web3.eth.sendSignedTransaction(signedTx.rawTransaction)
    },
    async manyTransfer(){
      // eslint-disable-next-line no-empty
      if (this.walletlist === '') {
        this.$message.error('钱包地址不能为空');
      }
      // eslint-disable-next-line no-console
      console.log(this.walletlist)
      // eslint-disable-next-line camelcase
      this.walletlist = this.walletlist.replace(/[\uFF0C]/g,',')  // 把中文逗号替换
      // eslint-disable-next-line camelcase
      const tolist = this.walletlist.split(',')
      const amount = this.nums
	  
      for (let i=0;i<tolist.length;i++){
        await this.transfermoney(amount,tolist[i],i)
      }
    }

  }


}
</script>
<style>

</style>
